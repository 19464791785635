const useTextModifiers = () => {
  const DataSourceStore = useDataSourceStore()
  const decodeHtmlEntity = (str: string) => {
    return str && str !== null
      ? str.replace(/&#(\d+);/g, (_match, dec) => String.fromCharCode(dec))
      : str
  }

  const re = new RegExp(
    Object.keys(DataSourceStore.dataSourceValues).join('|'),
    'g',
  )
  const replaceDataSourceValues = (str: string) => {
    return str && str !== null
      ? str.replace(
        re,
        (matched) => DataSourceStore.dataSourceValues[matched as keyof {}],
      )
      : str
  }
  const decodeAndReplaceText = (str: string) =>
    replaceDataSourceValues(decodeHtmlEntity(str))

  const emojiRegex = /<span data-type="emoji" data-name="([^"]+)"[^>]*>.*?<\/span>/g

  const emojiMap: Record<string, string> = {
    arrow_left_hook: '\u21A9\uFE0E',
    arrow_right_hook: '\u21AA\uFE0E',
  }

  const replaceEmoji = (str: string) => {
    return str.replace(emojiRegex, (match, emojiName) => {
      return emojiMap[emojiName] || match
    })
  }

  return {
    replaceDataSourceValues,
    decodeHtmlEntity,
    decodeAndReplaceText,
    replaceEmoji
  }
}
export default useTextModifiers
